import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, NgZone, SimpleChanges, OnChanges } from '@angular/core';
import { DataService } from 'src/app/services/data.service';


// Swiper support
import { register } from 'swiper/element/bundle';
register();

@Component({
  selector: 'app-mqa-modal',
  templateUrl: './mqa-modal.component.html',
  styleUrls: ['./mqa-modal.component.scss'],
})
export class MqaModalComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() show = false;
  @Input() mqaData: any = null;  // This is the JSON returning from backend
  @Output() showChange = new EventEmitter<boolean>();
  @Output() completed = new EventEmitter<any>();

  slider: any;
  answers: Record<number, string> = {}; // store answers keyed by question index
  currentSlideIndex = 0;
  selectedAnswer: string;
  localStorageKey = 'mqa_modal_gateway_questionnaire_id_';
  doneQuestionnaire: any = {
    id: 0,
    type: '',
    done: false,
    questions: []
  };

  constructor(
    public data: DataService,
    private zone: NgZone
  ) {
    this.localStorageKey = 'mqa_modal_gateway_questionnaire_id_' + this.data.pathway?.id;
  }

  ngOnInit() {
  }

  getPayloadReady() {
    this.doneQuestionnaire.id = this.mqaData.questionnaire_id;
    this.doneQuestionnaire.type = 'feedback';
    this.doneQuestionnaire.done = true;

    let payload = {
      id: this.mqaData.id,
      gateway_id: this.mqaData.id,
      questionnaire: this.doneQuestionnaire
    }

    return payload;
  }

  ngAfterViewInit() {
    // this.setupSwiper();
  }

  // Watch changes on @Input()s:
  ngOnChanges(changes: SimpleChanges) {
    // 1) If mqaData changes, filter out questions that have no options
    if (changes['mqaData'] && this.mqaData?.questions.length > 0) {
      this.mqaData.questions = this.mqaData.questions.filter(
        (q) => q.options?.length > 0
      );
    }

    // 2) Re-run setupSwiper when show becomes true
    if (changes['show'] && this.show === true) {
      // Defer a bit to let DOM update
      setTimeout(() => {
        this.setupSwiper();
      }, 500);
    }
  }

  setupSwiper() {
    // Turn off default pagination so we can do custom bullets
    const swiperParams = {
      slidesPerView: 1,
      pagination: false,
      loop: false,
      allowTouchMove: false,
    };

    const checkInterval = setInterval(() => {
      this.slider = document.getElementById('swiper-container-mqa-modal');
      if (this.slider) {
        Object.assign(this.slider, swiperParams);
        this.slider.initialize();
        clearInterval(checkInterval);
      }
    }, 500);
  }

  clearModal() {
    this.data.showMqaModal = false;
    this.mqaData = null;
    this.answers = {};
    this.currentSlideIndex = 0;
    this.selectedAnswer = '';
    this.doneQuestionnaire = {
      id: 0,
      type: '',
      done: false,
      questions: []
    };
    localStorage.removeItem(this.data.getMqaFetchDataKey());
  }

  selectAnswer(index: number, option: string) {
    // If user picks an answer for question index, set it
    this.answers[index] = option;
    this.selectedAnswer = option;
    console.log('Selected answer:', option);
    console.log('Selected answerss:', this.answers);

    // this.completeQuestionnaire(option);
  }

  slideNext() {
    // Ensure an answer is selected before proceeding
    if (!this.answers[this.currentSlideIndex]) {
      return;
    }
    // If this is the last slide, submit directly
    if (this.currentSlideIndex === this.mqaData?.questions.length - 1) {
      this.submitButton();
      return;
    }
    // Otherwise, move to the next slide
    this.slider.swiper.slideNext();
    this.currentSlideIndex++;
  }

  submitButton() {
    // Ensure the current slide has an answer
    if (!this.answers[this.currentSlideIndex]) {
      return;
    }
    // Gather all answers into doneQuestionnaire
    this.buildDoneQuestionnaire();
    // Proceed with final submission
    this.finishQuestionnaire();
    // Clear and close the modal
    this.clearModal();
  }

  buildDoneQuestionnaire() {
    // Reset array to avoid duplicates on subsequent opens
    this.doneQuestionnaire.questions = [];

    // Collect responses from the entire answers dictionary
    this.mqaData.questions.forEach((question, index) => {
      const selectedLabel = this.answers[index];
      if (selectedLabel) {
        const foundResponse = question.responses.find((resp) => resp.label === selectedLabel);
        if (foundResponse) {
          this.doneQuestionnaire.questions.push({
            id: question.id,
            question: question.question,
            selected: selectedLabel,
            image: question.image,
            answer: foundResponse,
          });
        }
      }
    });
  }

  // insertLastSelectedAnswer() {
  //   const lastSelectedAnswer = this.selectedAnswer;

  //   if (lastSelectedAnswer) {
  //     this.mqaData.questions.forEach((question, index) => {
  //       question.responses.forEach((response) => {
  //         if (response.label === lastSelectedAnswer) {

  //           this.doneQuestionnaire.questions.push({
  //             id: question?.id,
  //             question: question?.question,
  //             selected: lastSelectedAnswer,
  //             image: question?.image,
  //             answer: response
  //           });
  //         }
  //       });
  //     });
  //   }
  // }

  // Submit exactly like numericquestionnaire
  finishQuestionnaire() {
    try {
      // Prepare the payload
      let payload = this.getPayloadReady();

      console.log('Submitting MQA data to server:', payload);
      localStorage.setItem('questionnaire_answer_id', this.mqaData.questionnaire_id);
      
      // return;
      this.doneGateway(this.mqaData.id).then((response) => {
        //   console.log('doneGateway Feedback Response:', response);
        this.data.httpPost('feedbackSubmit', payload).then((response: any) => {
          console.log('Feedback Questionnaire Response:', response);
          this.completed.emit({ success: true, response: response });

          this.setAnalitycs(response);

        }).catch((error) => {
          this.completed.emit({ success: false, error: error });
          console.error('Error submitting feedback questionnaire data:', error);
        });
      }, (error) => {
        this.completed.emit({ success: false, error: error });
        console.error('Error marking gateway as done:', error);
      });
    } catch (error) {
      console.error('Error submitting feedback questionnaire data:', error);
      // Optionally emit an error
      this.completed.emit({ success: false, error: error });
    }
  }

  setAnalitycs(response: any = null) {
    localStorage.setItem('questionnaire_answer_id', response.questionnaire_answer_id);
    localStorage.setItem('questionnaire_id', response.questionnaire_id);

    this.data.setEventsAnalytics('Feedback Questionnaire Submitted', 'formSubmission', { product_zone: 'Screening', questionnaire_id: response.questionnaire_id, answer_id: response.questionnaire_answer_id });
  }

  doneGateway(gateway_id) {
    return this.data.httpPost('donegateway', { gateway_id });
  }


  public hasMqaBeenSubmitted(): boolean {
    const submittedAt = JSON.parse(localStorage.getItem('mqaSubmissionTimestamp' + this.data.pathway?.id + '-' + this.data.today));
    if (!submittedAt) {
      return false;
    }

    const date = new Date(submittedAt).toISOString().split('T')[0];
    const today = new Date().toISOString().split('T')[0];
    return date === today;
  }

  public setMqaSubmitted(value: boolean): void {
    const key = 'mqaSubmissionTimestamp' + this.data.pathway?.id + '-' + this.data.today;
    if (value == true) {
      localStorage.setItem(key, JSON.stringify(new Date(this.data.today).getTime()));
    } else {
      localStorage.removeItem(key);
    }
  }
}
