<div class="bullets-custom-steps">
  <ul class="progress" [style.--progress-percent]="progressPercent + '%'">
    <li
      *ngFor="let i of bulletIndexes"
      [ngClass]="{
        valid: i < current,
        active: i === current
      }"
    >
      <!-- The checkmark icon (only visible if .valid is set) -->
      <svg width="12" height="9" viewBox="0 0 12 9" fill="none">
        <path
          d="M11.3125 0.914062C11.5469 1.14844 11.5469 1.5 11.3125 1.71094L5.125 7.89844C4.91406 8.13281 4.5625 8.13281 4.35156 7.89844L1.16406 4.71094C0.929688 4.5 0.929688 4.14844 1.16406 3.91406C1.375 3.70312 1.72656 3.70312 1.9375 3.91406L4.75 6.72656L10.5391 0.914062C10.75 0.703125 11.1016 0.703125 11.3125 0.914062Z"
          fill="white"
        />
      </svg>
    </li>
  </ul>
</div>
