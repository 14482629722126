import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { InfozonebuttonComponent } from './infozonebutton/infozonebutton.component';
import { VideobuttonComponent } from './videobutton/videobutton.component';
import { InfozonemodalComponent } from './infozonemodal/infozonemodal.component';
import { VideomodalComponent } from './videomodal/videomodal.component';
import { QuestionnaireoutputmodalComponent } from './questionnaireoutputmodal/questionnaireoutputmodal.component';
import { BlockquestionnaireComponent } from './blockquestionnaire/blockquestionnaire.component';
import { NumericquestionnaireComponent } from './numericquestionnaire/numericquestionnaire.component';
import { GenericquestionnaireComponent } from './genericquestionnaire/genericquestionnaire.component';
import { HomealertComponent } from './homealert/homealert.component';
import { DesktopnavComponent } from './desktopnav/desktopnav.component';
import { MessagebubbleComponent } from './messagebubble/messagebubble.component';
import { RouterModule } from '@angular/router';
import { ExercisebuttonComponent } from './exercisebutton/exercisebutton.component';
import { ExerciseitemComponent } from './exerciseitem/exerciseitem.component';
import { ExercisemodalComponent } from './exercisemodal/exercisemodal.component';
import { SmblocktitleComponent } from './smblocktitle/smblocktitle.component';
import { SmblockimageComponent } from './smblockimage/smblockimage.component';
import { SmblockinfozoneComponent } from './smblockinfozone/smblockinfozone.component';
import { SmblockvideoComponent } from './smblockvideo/smblockvideo.component';
import { SmblocktextComponent } from './smblocktext/smblocktext.component';
import { SmblocktileitemComponent } from './smblocktileitem/smblocktileitem.component';
import { SmblocklistitemComponent } from './smblocklistitem/smblocklistitem.component';
import { SmblockslideitemComponent } from './smblockslideitem/smblockslideitem.component';
import { SmblockbuttonComponent } from './smblockbutton/smblockbutton.component';
import { GroupedquestionnaireComponent } from './groupedquestionnaire/groupedquestionnaire.component';
import { SmblockquestionnaireComponent } from './smblockquestionnaire/smblockquestionnaire.component';
import { SmblockexerciseComponent } from './smblockexercise/smblockexercise.component';
import { SmblockmonitorrecoveryComponent } from './smblockmonitorrecovery/smblockmonitorrecovery.component';
import { SmblockbooktreatmentComponent} from './smblockbooktreatment/smblockbooktreatment.component';
import { DomSanitizer } from '@angular/platform-browser'
import { PipeTransform, Pipe } from "@angular/core";
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TimepickermodalComponent } from './timepickermodal/timepickermodal.component';
import { MqaModalComponent } from './mqa-modal/mqa-modal.component';
import { BulletsComponent } from '../bullets/bullets.component';


export const GUB_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(public sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@NgModule({
  declarations: [
    InfozonebuttonComponent, 
    VideobuttonComponent, 
    InfozonemodalComponent, 
    VideomodalComponent, 
    BlockquestionnaireComponent, 
    NumericquestionnaireComponent, 
    GenericquestionnaireComponent, 
    HomealertComponent, 
    QuestionnaireoutputmodalComponent,
    DesktopnavComponent,
    MessagebubbleComponent,
    ExercisebuttonComponent,
    ExercisemodalComponent,
    ExerciseitemComponent,
    SafeHtmlPipe,
    SmblocktitleComponent,
    SmblockimageComponent,
    SmblockinfozoneComponent,
    SmblockvideoComponent,
    SmblocktextComponent,
    SmblocktileitemComponent,
    SmblocklistitemComponent,
    SmblockslideitemComponent,
    SmblockbuttonComponent,
    GroupedquestionnaireComponent,
    SmblockquestionnaireComponent,
    SmblockexerciseComponent,
    SmblockmonitorrecoveryComponent,
    SmblockbooktreatmentComponent,
    PasswordStrengthComponent,
    TimepickermodalComponent,
    MqaModalComponent,
    BulletsComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule
  ],
  exports: [
    SmblocktitleComponent,
    SmblockimageComponent,
    SmblockinfozoneComponent,
    SmblockvideoComponent,
    SmblocktextComponent,
    SmblocktileitemComponent,
    SmblocklistitemComponent,
    SmblockslideitemComponent,
    SmblockbuttonComponent,
    SmblockquestionnaireComponent,
    SmblockexerciseComponent,
    SmblockmonitorrecoveryComponent,
    SmblockbooktreatmentComponent,
    InfozonebuttonComponent, VideobuttonComponent, BlockquestionnaireComponent, NumericquestionnaireComponent, GenericquestionnaireComponent, HomealertComponent, DesktopnavComponent, MessagebubbleComponent, ExercisebuttonComponent, ExercisemodalComponent, ExerciseitemComponent, GroupedquestionnaireComponent, PasswordStrengthComponent, MqaModalComponent, BulletsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule { }
