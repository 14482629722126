<ion-modal [isOpen]="show" cssClass="mqa-modal" *ngIf="show" backdropDismiss="false" mode="ios"
>
    <ng-template>
        <div id="mqa-modal-template" [ngStyle]="{
            'display': 'flex',
            'flex-direction': 'column',
            'height': '100%',
            'max-height': '80%',
            'width': '100%',
            'max-width': '100%',
            'overflow-y': 'auto',
            'overflow-x': 'hidden',
            'background-color': 'white',
            'border-radius': '10px',
            'z-index': '1001',
            '--max-height': '800px'
        }">
            <!-- Main container with header/body/footer -->

            <!-- Show the default logo only if the current question has NO image -->
            <div class="logo-area" *ngIf="!mqaData?.questions[currentSlideIndex]?.image">
                <img src="/assets/gub_logo_medium.jpg" alt="getUbetter Logo" width="70" height="70" />
            </div>
            <div class="mqa-modal-page">
                <!-- Logo area -->

                <!-- Logo + bullet pagination + slides go in the body -->
                <div class="mqa-modal-body">
                    <!-- Custom bullet pagination at the top -->
                    <swiper-container id="swiper-container-mqa-modal" init="false" [pagination]="false">
                        <swiper-slide *ngFor="let q of mqaData?.questions; let i = index">
                            <!-- Slide image (if present) -->
                            <div class="slider-image" *ngIf="q.image">
                                <img [src]="q.image" alt="" />
                            </div>

                            <!-- Custom bullet pagination -->
                            <app-bullets [total]="mqaData?.questions.length" [current]="currentSlideIndex"
                            >
                                
                            </app-bullets>


                            <div class="question-area"
                            >
                                <!-- Question text: make it smaller -->
                                <h1 class="question-text">{{ q.question }}</h1>
                            </div>
                            <!-- Answers using checkboxes, highlight selected row -->
                            <div>
                                <ion-list class="answer-list">

                                    <ion-item class="answer-item"
                                    *ngFor="let opt of q.options" [ngClass]="{'selected-answer-modal': answers[i] === opt}" (click)="selectAnswer(i, opt)">
                                        <ion-label>{{ opt }}</ion-label>
                                        <ion-checkbox slot="end" [checked]="answers[i] === opt"></ion-checkbox>
                                    </ion-item>
                                </ion-list>
                            </div>

                        </swiper-slide>
                    </swiper-container>
                </div> <!-- .mqa-modal-body -->

                <!-- Footer with "Next question" button pinned at bottom -->

                

            </div> <!-- .mqa-modal -->
        </div>
        <div class="mqa-modal-footer">
            <ion-button *ngIf="currentSlideIndex < mqaData.questions.length - 1" (click)="slideNext()" [disabled]="!answers[currentSlideIndex]" class="button-mqa">
                <p>Next</p>
            </ion-button>
            <ion-button *ngIf="currentSlideIndex === mqaData.questions.length - 1" (click)="submitButton()" [disabled]="!answers[currentSlideIndex]" class="button-mqa" color="success">
                <p>Close</p>
            </ion-button>
        </div> <!-- .mqa-modal-footer -->
    </ng-template>
</ion-modal>
